<template>
	<div class="layerslist d-flex flex-column pa-0" style="width: 100%">
		<!-- Layer List -->
		<div v-if="!isSuperAdmin">
			<v-list flat dense style="background: transparent" class="pt-0">
				<!-- LEGEND -->
				<v-list-item class="pa-0">
					<v-list flat dense class="legend-container mt-2 ma-0" style="border-radius: 4px">
						<v-list-item v-for="(satelliteType, i) in satelliteImagesGroupedByType" :key="i" class="list-dense pa-0">
							<v-tooltip v-if="satelliteType.description" right nudge-right="10" :color="primaryColor" transition="scroll-x-transition">
								<template v-slot:activator="{ on, attrs }">
									<v-list-item-title class="d-flex align-center justify-start pl-3 ma-0 pointer" v-bind="attrs" v-on="on">
										<span class="legend-color" :style="'background-color:' + satelliteType.color"></span>
										<span class="fs-sm fw-500 color-black pl-3">{{ satelliteType.name }}</span>
										<v-icon size="12" class="pl-2 fw-500">fak fa-line-info-2</v-icon>
									</v-list-item-title>
								</template>
								<span class="fs-regular">{{ satelliteType.description }}</span>
							</v-tooltip>
						</v-list-item>
					</v-list>
				</v-list-item>
				<!-- DATE PICKER -->
				<v-list-item class="pa-0">
					<v-date-picker
						v-model="dateSelected[0]"
						first-day-of-week="1"
						:show-adjacent-months="false"
						:color="primaryColor"
						class="pa-2 mt-2 rounded"
						:min="firstDateAvailable"
						:max="lastDateAvailable"
						:allowed-dates="allowedDates"
						:events="functionEvents"
						:locale="userLanguage"
						full-width
						no-title
						@input="getSatelliteImage(imagesByDate, 0)"
					></v-date-picker>
				</v-list-item>
				<!-- RESULTS -->
				<v-list-item class="pa-0" style="min-height: auto !important">
					<v-list v-if="selectedImage[0]" flat dense style="background: transparent" class="width-100 pt-0">
						<v-list-group v-for="(imageType, k) in selectedImage[0]" :key="k" class="pt-2">
							<template #activator>
								<v-list-item-title :title="imageType.name" class="fs-regular fw-semi">{{ imageType.name }}</v-list-item-title>
							</template>
							<div v-for="(pollutant, k) in imageType.images" :key="k">
								<v-list dense style="background: transparent !important" class="layer-list py-0">
									<layer-list-tooltip
										:value="true"
										:active="isActive(pollutant.url)"
										@update:active="(value) => setActive(pollutant.url, value)"
										:title="pollutant.pollutant"
										:tooltipTitle="getParameterTooltip(pollutant.pollutant)"
										:organization="pollutant.organizationacronym"
										:noPadding="true"
										border-bottom
									>
									</layer-list-tooltip>
								</v-list>
							</div>
						</v-list-group>
					</v-list>
				</v-list-item>
			</v-list>
		</div>
		<div v-else>
			<div v-for="(item, i) in satelliteImagesGroupedByOrganization" :key="i">
				<v-list flat dense style="background: transparent" class="pt-0">
					<v-list-group v-model="isOpened[i]" @input="collapseOthers(i, item.pmorganizationid)">
						<template #activator>
							<v-list-item-title class="fw-bold fs-regular ml-n4">{{ item.organizationname }}</v-list-item-title>
						</template>

						<v-list flat dense style="background: transparent" class="pt-0 mt-2">
							<!-- LEGEND -->
							<v-list-item class="pa-0">
								<v-list flat dense class="legend-container mt-2 ma-0" style="border-radius: 4px">
									<v-list-item v-for="(satelliteType, i) in item.satelliteType" :key="i" class="list-dense pa-0">
										<v-tooltip
											v-if="satelliteType.description"
											right
											nudge-right="10"
											:color="primaryColor"
											transition="scroll-x-transition"
										>
											<template v-slot:activator="{ on, attrs }">
												<v-list-item-title
													class="d-flex align-center justify-start pl-3 ma-0 pointer"
													v-bind="attrs"
													v-on="on"
												>
													<span class="legend-color" :style="'background-color:' + satelliteType.color"></span>
													<span class="fs-sm fw-500 color-black pl-3">{{ satelliteType.name }}</span>
													<v-icon size="12" class="pl-2 fw-500">fak fa-line-info-2</v-icon>
												</v-list-item-title>
											</template>
											<span class="fs-regular">{{ satelliteType.description }}</span>
										</v-tooltip>
									</v-list-item>
								</v-list>
							</v-list-item>
							<!-- DATE PICKER -->
							<v-list-item class="pa-0">
								<v-date-picker
									v-model="dateSelected[i]"
									first-day-of-week="1"
									:show-adjacent-months="false"
									:color="primaryColor"
									class="pa-2 mt-2 rounded"
									:min="firstDateAvailableByOrg[i]"
									:max="lastDateAvailableByOrg[i]"
									:allowed-dates="allowedDatesByOrg"
									:events="functionEvents"
									:locale="userLanguage"
									full-width
									no-title
									@input="getSatelliteImage(imagesByDate, i)"
								></v-date-picker>
							</v-list-item>
							<!-- RESULTS -->
							<v-list-item class="pa-0" style="min-height: auto !important">
								<v-list v-if="selectedImage[i]" flat dense style="background: transparent" class="width-100 pt-0">
									<v-list-group v-for="(imageType, k) in selectedImage[i]" :key="k" class="pt-2">
										<template #activator>
											<v-list-item-title :title="imageType.name" class="fw-semi fs-regular">{{
												imageType.name
											}}</v-list-item-title>
										</template>
										<div v-for="(pollutant, k) in imageType.images" :key="k">
											<v-list dense style="background: transparent !important" class="layer-list py-0">
												<layer-list-tooltip
													:value="true"
													:active="isActive(pollutant.url)"
													@update:active="(value) => setActive(pollutant.url, value, item.pmorganizationid)"
													:title="pollutant.pollutant"
													:tooltipTitle="getParameterTooltip(pollutant.pollutant)"
													:organization="pollutant.organizationacronym"
													:noPadding="true"
													border-bottom
												>
												</layer-list-tooltip>
											</v-list>
										</div>
									</v-list-group>
								</v-list>
							</v-list-item>
						</v-list>
					</v-list-group>
				</v-list>
			</div>
		</div>
	</div>
</template>

<script>
import VIZ_DEFINITIONS from '@/components/map/layers';
import { RASTER_LAYER_PROPERTIES } from '@/lib/constants/layers';
import { search, isSuperAdmin } from '../../../../../api/common';
import ol2map from '@/components/map/sections/map/subcomponents/ol2map';
import * as olProj from 'ol/proj';
import * as olExtent from 'ol/extent';

import LayerListTooltip from './subpanels/common/LayerListTooltip.vue';

export default {
	name: 'layersrastersection',

	data() {
		return {
			allSatelliteImages: [],
			satelliteImagesGroupedByType: [],
			satelliteImagesGroupedByOrganization: [],
			imagesByDate: [],
			profile: '',
			isSuperAdmin: false,
			dateSelected: [],
			oldDateSelected: [],
			formDisabled: false,
			selectedImage: [],
			firstDateAvailableByOrg: [],
			lastDateAvailableByOrg: [],
			firstDateAvailable: null,
			lastDateAvailable: null,
			rasterLayersState: null,
			paddingModifier: false,
			allowedDatesArray: [],
			allowedDatesFiltered: [],
			primaryColor: this.$store.state.global.primaryColor,
			isOpened: {}
		};
	},
	components: {
		//LayerListTitle,
		LayerListTooltip
	},
	computed: {
		/* isActive() {
			if (this.$store.state.settingsPanel.isOpened.layersrasterSettings) {
				return (layerId) =>
					this.$store.state.mappanel.rasterLayersSettings[layerId]
						? this.$store.state.mappanel.rasterLayersSettings[layerId]['active']
						: false;
			}
			if (this.$store.state.compareMapPanel.isOpened.layersrasterCompareMap1) {
				return (layerId) =>
					this.$store.state.mappanel.rasterLayersCompareMap1[layerId]
						? this.$store.state.mappanel.rasterLayersCompareMap1[layerId]['active']
						: false;
			}
			if (this.$store.state.compareMapPanel.isOpened.layersrasterCompareMap2) {
				return (layerId) =>
					this.$store.state.mappanel.rasterLayersCompareMap2[layerId]
						? this.$store.state.mappanel.rasterLayersCompareMap2[layerId]['active']
						: false;
			}

			return () => false;
		}, */
		rasterLayers() {
			return this.$store.getters.rasterLayers;
		},
		rasterOpacity: {
			get() {
				return this.$store.state.mappanel.rasterOpacity;
			},

			set(value) {
				this.$store.commit('setRasterOpacity', value);
			}
		},
		rasterOpacityCompareMap1: {
			get() {
				return this.$store.state.mappanel.rasterOpacityCompareMap1;
			},

			set(value) {
				this.$store.commit('setRasterOpacityCompareMap1', value);
			}
		},
		rasterOpacityCompareMap2: {
			get() {
				return this.$store.state.mappanel.rasterOpacityCompareMap2;
			},

			set(value) {
				this.$store.commit('setRasterOpacityCompareMap2', value);
			}
		},
		isRasterActive: {
			get() {
				return this.$store.getters.isRasterActive;
			},
			set(value) {
				this.$store.commit('setIsRasterActive', value);
			}
		},
		isRasterCompareMap1Active: {
			get() {
				return this.$store.getters.isRasterCompareMap1Active;
			},
			set(value) {
				this.$store.commit('setIsRasterCompareMap1Active', value);
			}
		},
		isRasterCompareMap2Active: {
			get() {
				return this.$store.getters.isRasterCompareMap2Active;
			},
			set(value) {
				this.$store.commit('setIsRasterCompareMap2Active', value);
			}
		},
		activeRasterLayer: {
			get() {
				return this.$store.getters.getActiveRasterLayer;
			},
			set(value) {
				this.$store.commit('setActiveRasterLayer', value);
			}
		},
		activeCompareMap1RasterLayer: {
			get() {
				return this.$store.getters.getActiveCompareMap1RasterLayer;
			},
			set(value) {
				this.$store.commit('setActiveCompareMap1RasterLayer', value);
			}
		},
		activeCompareMap2RasterLayer: {
			get() {
				return this.$store.getters.getActiveCompareMap2RasterLayer;
			},
			set(value) {
				this.$store.commit('setActiveCompareMap2RasterLayer', value);
			}
		},
		userLanguage() {
			return this.userProperties.language;
		}
	},
	watch: {
		activeRasterLayer: {
			handler(newValue, oldValue) {
				console.log('active raster watcher', oldValue, newValue);
				if (newValue) {
					if (this.$store.state.settingsPanel.isOpened.layersrasterSettings) {
						this.$store.state.mappanel.rasterLayersSettings[newValue]['active'] = true;
					}
					/*console.log('active raster watcher from', oldValue, ' to ', newValue);
					console.log(
						'active raster watcher from',
						oldValue ? this.rasterLayers[oldValue] : 'no old value',
						' to ',
						this.rasterLayers[newValue]
					);*/
				}

				if (oldValue) {
					if (this.$store.state.settingsPanel.isOpened.layersrasterSettings) {
						this.$store.state.mappanel.rasterLayersSettings[oldValue]['active'] = false;
					}
					ol2map.setVisibility(oldValue, false);
					ol2map.setOpacity(oldValue, this.rasterOpacity * 0.01);
				}

				//console.log('active raster watcher', this.isActive(oldValue), this.isActive(newValue));
				/* this.rasterLayers[oldValue]['active'] = false; */
			}
		},

		activeCompareMap1RasterLayer: {
			handler(newValue, oldValue) {
				if (newValue) {
					if (this.$store.state.compareMapPanel.isOpened.layersrasterCompareMap1) {
						this.$store.state.mappanel.rasterLayersCompareMap1[newValue]['active'] = true;
					}
				}

				if (oldValue) {
					if (this.$store.state.compareMapPanel.isOpened.layersrasterCompareMap1) {
						this.$store.state.mappanel.rasterLayersCompareMap1[oldValue]['active'] = false;
					}
					ol2map.setVisibility(oldValue, false);
					ol2map.setOpacity(oldValue, this.rasterOpacityCompareMap1 * 0.01);
				}

				//console.log('active raster watcher', this.isActive(oldValue), this.isActive(newValue));
			}
		},

		activeCompareMap2RasterLayer: {
			handler(newValue, oldValue) {
				if (newValue) {
					if (this.$store.state.compareMapPanel.isOpened.layersrasterCompareMap2) {
						this.$store.state.mappanel.rasterLayersCompareMap2[newValue]['active'] = true;
					}
				}

				if (oldValue) {
					if (this.$store.state.compareMapPanel.isOpened.layersrasterCompareMap2) {
						this.$store.state.mappanel.rasterLayersCompareMap2[oldValue]['active'] = false;
					}
					ol2map.setVisibility(oldValue, false);
					ol2map.setOpacity(oldValue, this.rasterOpacityCompareMap2 * 0.01);
				}

				//console.log('active raster watcher', this.isActive(oldValue), this.isActive(newValue));
			}
		}
	},

	created() {
		// restore changes before create (persist changes)
		this.dateSelected = this.$store.state.mappanel.rasterDateSelected;
		this.selectedImage = this.$store.state.mappanel.rasterSelectedImage;
		if (this.activeRasterLayer) this.setActive(this.activeRasterLayer, true);
		console.log('created layersrasterlist');
		this.isSuperAdmin = isSuperAdmin(this.session.profiles[0]);

		//console.log('created raster list', this.isSuperAdmin);
		if (!this.isSuperAdmin) {
			this.afterGetData();
			this.paddingModifier = false;
		} else {
			this.afterGetData_SUPERADMIN();
			this.paddingModifier = true;
		}
	},
	mounted() {
		if (this.allSatelliteImages.length > 0) {
			if (!this.isSuperAdmin) {
				const uniqueDatesSet = new Set(this.allSatelliteImages.map((obj) => obj.date.split('T')[0]));
				this.allowedDatesArray = Array.from(uniqueDatesSet);
				//console.log('debug satellite dates', this.allSatelliteImages, uniqueDatesSet, this.allowedDatesArray);
			} else {
				const uniqueDatesByOrg = new Set();
				const arrayOfUniqueDatesByOrg = this.allSatelliteImages
					.filter((obj) => {
						const key = `${obj.pmorganizationid}-${obj.date}`;
						if (!uniqueDatesByOrg.has(key)) {
							uniqueDatesByOrg.add(key);
							return true;
						}
						return false;
					})
					.map((obj) => ({
						pmorganizationid: obj.pmorganizationid,
						organizationacronym: obj.organizationacronym,
						date: obj.date.split('T')[0]
					}));
				this.allowedDatesArray = arrayOfUniqueDatesByOrg;
				//console.log('debug satellite dates', arrayOfUniqueDatesByOrg);
			}
		}
	},
	beforeDestroy() {
		console.log('beforeDestroy layersrasterlist');
		this.$store.commit('setRasterDateSelected', this.dateSelected);
		this.$store.commit('setRasterSelectedImage', this.selectedImage);
		// commit changes before destroy (persist changes)
	},
	props: {
		rasterlist: {
			type: String,
			required: true
		}
	},
	methods: {
		setActive(layer, active, organizationid = null) {
			//this.$store.commit('setClickMap', false);
			//this.$store.commit('setClickMapCompare', false);

			console.log('raster set Active', layer, active, this.$store.state.mappanel.rasterLayersSettings, organizationid);
			if (this.isRasterActive && document.getElementById('popup-element')) {
				document.getElementById('popup-element').setAttribute('hidden', true);
			}

			if (this.$store.state.settingsPanel.isOpened.layersrasterSettings) {
				this.$store.state.mappanel.rasterLayersSettings[layer]['active'] = active;
				if (active) {
					this.activeRasterLayer = layer;
				} else if (!active && layer == this.activeRasterLayer) {
					this.activeRasterLayer = null;
				}
				ol2map.setVisibility(layer, active);
				ol2map.setOpacity(layer, this.$store.state.mappanel.rasterOpacity * 0.01);
			}

			if (this.$store.state.compareMapPanel.isOpened.layersrasterCompareMap1) {
				this.$store.state.mappanel.rasterLayersCompareMap1[layer]['active'] = active;
				if (active) {
					this.activeCompareMap1RasterLayer = layer;
				} else if (!active && layer == this.activeCompareMap1RasterLayer) {
					this.activeCompareMap1RasterLayer = null;
				}
				ol2map.setVisibility(layer, active);
				ol2map.setOpacity(layer, this.$store.state.mappanel.rasterOpacityCompareMap1 * 0.01);
			}

			if (this.$store.state.compareMapPanel.isOpened.layersrasterCompareMap2) {
				this.$store.state.mappanel.rasterLayersCompareMap2[layer]['active'] = active;
				if (active) {
					this.activeCompareMap2RasterLayer = layer;
				} else if (!active && layer == this.activeCompareMap2RasterLayer) {
					this.activeCompareMap2RasterLayer = null;
				}
				ol2map.setVisibility(layer, active);
				ol2map.setOpacity(layer, this.$store.state.mappanel.rasterOpacityCompareMap2 * 0.01);
			}

			if (organizationid) {
				this.centerOrganizationMap(organizationid);
			}
		},

		async afterGetData_SUPERADMIN() {
			if (this.activeRasterLayer) {
				this.setActive(this.activeRasterLayer, false);
			}

			if (!this.$store.getters.rasterLayersData) {
				// Filtro por organización
				let filter = {
					groups: [],
					groupOp: 'and',
					rules: [
						{ field: 'disabled', op: 'eq', data: 0 },
						{ field: 'satellitedisabled', op: 'eq', data: 0 }
					]
				};
				const body = {
					model: 'pmsatelliteimages',
					filter: filter,
					rows: 9999 // Para obtener todos los elementos
				};

				const { data } = await search(body);
				this.allSatelliteImages = data.data;
				this.$store.commit('setRasterLayersData', this.allSatelliteImages);
			} else {
				this.allSatelliteImages = this.$store.getters.rasterLayersData;
			}
			this.createRasterListState(this.allSatelliteImages);
			//console.log('-- Images retrieved --', this.allSatelliteImages);
			this.satelliteImagesGroupedByOrganization = this.groupByOrganization(this.allSatelliteImages);
			//console.log('Images grouped by organization', this.satelliteImagesGroupedByOrganization);

			for (const element of this.satelliteImagesGroupedByOrganization) {
				//console.log(element.satelliteType);
				element.satelliteType = this.groupBySatelliteType(element.satelliteType);
				for (const item of element.satelliteType) {
					item.images = this.groupByDate(item.images);
				}
			}

			let tempSatelliteImages = this.groupByOrganization(this.allSatelliteImages);
			for (const element of tempSatelliteImages) {
				//console.warn('element debug', element);

				/* //test lowest and highest dates available
				this.imagesByDate[tempSatelliteImages.indexOf(element)] = this.groupByDate(element.satelliteType); */

				element.satelliteType = this.groupByDate(element.satelliteType);
				// Find the object with the lowest date
				const lowestDateObject = element.satelliteType.reduce((prev, current) => {
					const prevDate = new Date(prev.date);
					const currentDate = new Date(current.date);
					return prevDate < currentDate ? prev : current;
				});

				// Find the object with the highest date
				const highestDateObject = element.satelliteType.reduce((prev, current) => {
					const prevDate = new Date(prev.date);
					const currentDate = new Date(current.date);
					return prevDate > currentDate ? prev : current;
				});
				//console.log('satelliteImagesGroupedByType', element, element.satelliteType, lowestDateObject, highestDateObject);

				this.firstDateAvailableByOrg[tempSatelliteImages.indexOf(element)] = lowestDateObject.date;
				this.lastDateAvailableByOrg[tempSatelliteImages.indexOf(element)] = highestDateObject.date;
			}

			/*console.log(
				'Images grouped by all parameters',
				this.allSatelliteImages,
				this.firstDateAvailableByOrg,
				this.lastDateAvailableByOrg,
				this.satelliteImagesGroupedByOrganization
			);*/
		},

		async afterGetData() {
			/* if (this.activeRasterLayer) {
				this.setActive(this.activeRasterLayer, false);
			} */

			if (!this.$store.getters.rasterLayersData) {
				// Filtro por organización
				let filterByOrganization = {
					groups: [],
					groupOp: 'and',
					rules: [
						{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid },
						{ field: 'disabled', op: 'eq', data: 0 },
						{ field: 'satellitedisabled', op: 'eq', data: 0 }
					]
				};

				const body = {
					model: 'pmsatelliteimages',
					filter: filterByOrganization,
					rows: 9999 // Para obtener todos los elementos
				};

				const { data } = await search(body);
				this.allSatelliteImages = data.data;
				this.$store.commit('setRasterLayersData', this.allSatelliteImages);
			} else {
				this.allSatelliteImages = this.$store.getters.rasterLayersData;
			}

			this.createRasterListState(this.allSatelliteImages);

			//console.log('-- Images retrieved --', this.allSatelliteImages);

			this.satelliteImagesGroupedByType = this.groupBySatelliteType(this.allSatelliteImages);
			//console.log('Images grouped by name', this.satelliteImagesGroupedByType);

			//test lowest and highest dates available
			this.imagesByDate = this.groupByDate(this.allSatelliteImages);
			// Find the object with the lowest date
			const lowestDateObject = this.imagesByDate.reduce((prev, current) => {
				const prevDate = new Date(prev.date);
				const currentDate = new Date(current.date);
				return prevDate < currentDate ? prev : current;
			});

			// Find the object with the highest date
			const highestDateObject = this.imagesByDate.reduce((prev, current) => {
				const prevDate = new Date(prev.date);
				const currentDate = new Date(current.date);
				return prevDate > currentDate ? prev : current;
			});
			//console.log('satelliteImagesGroupedByType', this.allSatelliteImages, lowestDateObject, highestDateObject);
			this.firstDateAvailable = lowestDateObject.date;
			this.lastDateAvailable = highestDateObject.date;

			/* for (const element of this.satelliteImagesGroupedByType) {
				element.images = this.groupByDate(element.images);
				// Find the object with the lowest date
				const lowestDateObject = element.images.reduce((prev, current) => {
					const prevDate = new Date(prev.date);
					const currentDate = new Date(current.date);
					return prevDate < currentDate ? prev : current;
				});

				// Find the object with the highest date
				const highestDateObject = element.images.reduce((prev, current) => {
					const prevDate = new Date(prev.date);
					const currentDate = new Date(current.date);
					return prevDate > currentDate ? prev : current;
				});
				console.log('satelliteImagesGroupedByType', element, element.images, lowestDateObject, highestDateObject);

				this.firstDateAvailable[this.satelliteImagesGroupedByType.indexOf(element)] = lowestDateObject.date;
				this.lastDateAvailable[this.satelliteImagesGroupedByType.indexOf(element)] = highestDateObject.date;
			} */

			//console.log('Images grouped by all parameters', this.allSatelliteImages, this.firstDateAvailable);

			//this.secondOption(this.allSatelliteImages);
		},

		createRasterListState(dataset) {
			const object = dataset.reduce((r, { acronym, url, pollutant }) => {
				var identifier = url;
				r[identifier] = { identifier: identifier, active: false, url: url, acronym: acronym, title: pollutant };
				return r;
			}, {});

			this.$store.commit('setRasterLayersSettings', object);

			if (ol2map.getMapCompareInstance()) {
				if (this.rasterlist === 'rasterlistCompareMap1') {
					this.$store.commit('setRasterLayersCompareMap1', object);
				}
				if (this.rasterlist === 'rasterlistCompareMap2') {
					this.$store.commit('setRasterLayersCompareMap2', object);
				}
			}
			this.loadRasterImages(Object.values(object));
		},

		loadRasterImages(dataset) {
			//var ref = this.$refs[this.ref].methodName(); // methodName es el nombre del método que quieres llamar en el componente hijo
			dataset.forEach((layer) => {
				var layerRasterProperties = VIZ_DEFINITIONS[RASTER_LAYER_PROPERTIES];
				layerRasterProperties.viz.layerName = layer.url;
				layerRasterProperties.viz.title = layer.title;
				if (this.rasterlist === 'rasterlistCompareMap2') {
					ol2map.createWMSLayer(layer.identifier, layerRasterProperties, ol2map.getMapCompareInstance());
					//ol2map.createWMSLayerCompareMap(layer.identifier, layerRasterProperties, ol2map.getMapInstance());
				} else {
					ol2map.createWMSLayer(layer.identifier, layerRasterProperties, ol2map.getMapInstance());
				}
			});
		},

		groupBySatelliteType(dataset) {
			let sortedDataset = dataset;
			if (dataset[0].pollutant) {
				sortedDataset = Object.values(dataset).sort((a, b) => {
					const elementA = a.pollutant.toUpperCase(); /* a.pollutant.split(' ')[0].toUpperCase() */
					const elementB = b.pollutant.toUpperCase(); /* b.pollutant.split(' ')[0].toUpperCase() */
					return elementA.localeCompare(elementB);
				});
			}

			const object = sortedDataset.reduce((r, { name, color, description, ...rest }) => {
				if (!r[name]) r[name] = { name, color, description, images: [{ name, color, ...rest }] };
				else r[name].images.push({ name, color, description, ...rest });
				return r;
			}, {});
			const result = Object.values(object).sort((a, b) => {
				const elementA = a.name.toUpperCase();
				const elementB = b.name.toUpperCase();
				return elementA.localeCompare(elementB);
			});
			//console.debug('satellite group', dataset, result);
			return result;
		},

		groupByOrganization(dataset) {
			const object = dataset.reduce((r, { organizationname, pmorganizationid, ...rest }) => {
				if (!r[organizationname]) r[organizationname] = { organizationname, pmorganizationid, satelliteType: [rest] };
				else r[organizationname].satelliteType.push(rest);
				return r;
			}, {});

			const result = Object.values(object).sort((a, b) => {
				const elementA = a.organizationname.toUpperCase();
				const elementB = b.organizationname.toUpperCase();
				return elementA.localeCompare(elementB);
			});
			return result;
		},

		groupByDate(dataset) {
			const object = dataset.reduce((r, { date, ...rest }) => {
				if (!r[date]) r[date] = { date, pollutants: [{ date, ...rest }] };
				else r[date].pollutants.push({ date, ...rest });
				return r;
			}, {});

			const result = Object.values(object);
			return result;
		},

		/*secondOption(dataset) {
			const itemsObject = dataset.reduce((accumulator, currentValue) => {
				if (accumulator[currentValue.name]) {
					accumulator[currentValue.name].push(currentValue);
				} else {
					accumulator[currentValue.name] = [currentValue];
				}
				return accumulator;
			}, {});

			const result = Object.keys(itemsObject).map((k) => ({ name: k, images: itemsObject[k] }));

			//console.log('-- Grouped by --');
			console.log(result);
		},*/
		getSatelliteImage(images, index, clearImage = false /* , organizationid = null */) {
			if (clearImage) this.clearSatelliteImage(index);
			console.log('debug getSatelliteImage', images, index, this.dateSelected, this.oldDateSelected);

			if (this.oldDateSelected[index] && this.oldDateSelected[index] == this.dateSelected[index]) {
				this.dateSelected[index] = this.oldDateSelected[index] = this.selectedImage[index] = null;
			} else {
				if (this.dateSelected[index]) {
					this.oldDateSelected[index] = this.dateSelected[index];
					var dateFixed = new Date(this.dateSelected[index]).addHours(3);
					//console.warn('dateFixed', dateFixed, dateFixed.toISOString().split('T')[0]);
					let selectedImage = images.filter((image) => image.date.split('T')[0] === dateFixed.toISOString().split('T')[0]);

					//console.warn('selected image: ', selectedImage, selectedImage.length);
					if (selectedImage.length > 0) {
						//this.selectedImage[index] = selectedImage[0];
						this.selectedImage[index] = this.groupBySatelliteType(selectedImage[0].pollutants);
						/*console.info(
							'pollutants by type',
							selectedImage[0],
							this.selectedImage[0],
							this.groupBySatelliteType(selectedImage[0].pollutants)
						);*/
					} else {
						//this.clearSatelliteImage(index);
						this.$puiNotify.info(this.$t('rasterTools.noDataLastSelection'));
					}
				} else {
					//console.log('clear sat data');
					//this.clearSatelliteImage(index);
				}
			}

			//console.log('info: getSatelliteImage', this.activeRasterLayer, this.selectedImage);
		},
		clearSatelliteImage(index) {
			this.selectedImage[index] = null;
			if (this.activeRasterLayer) {
				this.setActive(this.activeRasterLayer, false);
			}
		},
		getParameterName(acronym) {
			/* console.log(
				'parameter name sat ' + acronym,
				this.$store.getters.getSatelliteParameters,
				this.$store.getters.getSatelliteParameters.find((parameter) => parameter.acronym == acronym)
			); */
			let parameter = this.$store.getters.getSatelliteParameters.find((parameter) => parameter.acronym == acronym);
			if (parameter) {
				return parameter.acronym + ' (' + parameter.name + ')';
			}
			return acronym;
		},
		getParameterTooltip(acronym) {
			//console.log('parameter tooltip sat ' + acronym, acronym.split(' ')[0], this.$store.getters.getSatelliteParameters);
			let parameter = this.$store.getters.getSatelliteParameters.find((parameter) => parameter.acronym == acronym.split(' ')[0]);
			if (parameter) {
				return parameter.name + (parameter.description ? ' - ' + parameter.description : '');
			}
			return null;
		},
		allowedDates(val) {
			return this.allowedDatesArray.indexOf(val) !== -1;
		},
		allowedDatesByOrg(val) {
			//console.info('info dates by org', this.allowedDatesFiltered, this.allowedDatesFiltered.indexOf(val) !== -1);
			return this.allowedDatesFiltered.indexOf(val) !== -1;
		},
		filteredDates(organizationid) {
			return this.allowedDatesArray.filter((dateObj) => dateObj.pmorganizationid === organizationid).map((dateObj) => dateObj.date);
		},
		collapseOthers(i, organizationid = null) {
			//console.log("collapseOthers", i)
			if (organizationid) {
				this.allowedDatesFiltered = this.allowedDatesArray.filter((obj) => obj.pmorganizationid === organizationid).map((obj) => obj.date);
				let organizationImages = this.groupByOrganization(this.allSatelliteImages).find(
					(organizationImage) => organizationImage.pmorganizationid == organizationid
				);
				this.imagesByDate = this.groupByDate(organizationImages.satelliteType);
				//console.log("organizationid", organizationid)
			}

			//console.debug('debug collapse', i, organizationid, this.allowedDatesFiltered, this.allowedDatesArray);
			for (var panel in this.isOpened) {
				if (panel != i) {
					this.isOpened[panel] = false;
				}
			}

			/* let calendarRight = document.getElementsByClassName('fa-chevron-right')[0];
			let calendarLeft = document.getElementsByClassName('fa-chevron-left')[0];
			if (calendarLeft && calendarRight) {
				calendarRight.classList.add('fak', 'fa-line-next');
				calendarRight.classList.remove('fa-chevron-right');
				calendarLeft.classList.add('fak', 'fa-line-back');
				calendarLeft.classList.remove('fa-chevron-left');
			} */
		},
		async centerOrganizationMap(organizationid) {
			const filterOrganization = {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'pmorganizationid', op: 'eq', data: organizationid }]
			};

			let body = {
				model: 'pmorganization',
				filter: filterOrganization,
				rows: 1
			};
			const { data } = await search(body);
			//console.log('debug centerOrganizationMap', data.data);
			var organization = data.data;

			var polygon = [
				[organization[0].nelon, organization[0].nelat],
				[organization[0].nelon, organization[0].swlat],
				[organization[0].swlon, organization[0].swlat],
				[organization[0].swlon, organization[0].nelat]
			];
			console.log('debug centerOrganizationMap', polygon);

			ol2map.zoomToExtent(olProj.transformExtent(new olExtent.boundingExtent(polygon), 'EPSG:4326', 'EPSG:3857'));
		},
		functionEvents(date) {
			//console.debug('debug function events 1', this.allSatelliteImages, date, this.imagesByDate);
			let dateImage = null;
			if (!this.isSuperAdmin) {
				//filter by organization
				dateImage = this.imagesByDate.find((image) => image.date.split('T')[0] === date.split('T')[0]);
			} else {
				dateImage = this.imagesByDate.find((image) => image.date.split('T')[0] === date.split('T')[0]);
			}
			let colorEvents = [];
			//console.debug('debug function events 2', this.allSatelliteImages, date, dateImage, !!dateImage);
			if (dateImage) {
				dateImage.pollutants.forEach((image) => {
					if (!colorEvents.includes(image.color)) colorEvents.push(image.color);
				});
			}
			if (colorEvents.length > 0) return colorEvents;
			return false;
		},
		isActive(layerId) {
			console.log('isActive', layerId, this.$store.state.mappanel.rasterLayersSettings);
			if (this.$store.state.settingsPanel.isOpened.layersrasterSettings) {
				return this.$store.state.mappanel.rasterLayersSettings[layerId]
					? this.$store.state.mappanel.rasterLayersSettings[layerId]['active']
					: false;
			}
			if (this.$store.state.compareMapPanel.isOpened.layersrasterCompareMap1) {
				return this.$store.state.mappanel.rasterLayersCompareMap1[layerId]
					? this.$store.state.mappanel.rasterLayersCompareMap1[layerId]['active']
					: false;
			}
			if (this.$store.state.compareMapPanel.isOpened.layersrasterCompareMap2) {
				return this.$store.state.mappanel.rasterLayersCompareMap2[layerId]
					? this.$store.state.mappanel.rasterLayersCompareMap2[layerId]['active']
					: false;
			}

			return () => false;
		}
	}
};
</script>

<style lang="postcss">
@import '../../../../../styles/eiffel-variables.pcss';

.layerslist {
	& .subheader {
		font-size: 10px;
		color: var(--azul-primary-100);

		& .selector {
			color: var(--azul-primary-100);
			font-size: 0.9rem;
			line-height: 1.33;

			&:hover {
				cursor: pointer;
				font-weight: 900;
			}
		}
	}

	& .bottomborder {
		border-bottom: 1px solid var(--moderate);
	}

	& .legend-color {
		width: 10px !important;
		height: 10px !important;
		border-radius: 10px;
	}

	& .legend-container {
		background-color: #fff;
		width: 100% !important;
		border-radius: 4px;
		margin: 0px 0px;
	}

	& .list-dense {
		min-height: 20px !important;
	}

	& .v-date-picker-table__events > div {
		width: 6px !important;
		height: 6px !important;
	}

	&.v-picker--date .v-btn {
		border-bottom-left-radius: 16px;
		border-bottom-right-radius: 16px;
		border-top-left-radius: 16px;
		border-top-right-radius: 16px;
		border-radius: 10px !important;
	}

	& .v-date-picker-table--date {
		& td {
			height: 36px !important;
		}

		& .v-btn {
			width: 26px !important;
			height: 26px !important;
			border-radius: 50% !important;
		}

		& .v-date-picker-table__events {
			bottom: 0px !important;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-content: space-between;
			height: 13px !important;
			width: 26px;
			top: 27px;
			margin-left: 0px;
		}
	}
}
</style>
